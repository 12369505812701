import { ReactElement, useEffect, useState } from "react";
import { collection, DocumentData, getDocs } from "firebase/firestore/lite";
import { ParsedToken } from "firebase/auth";

import { db } from "../../firebase";
import * as React from "react";
import Form from './Form';
import "./posting.css";

type Props = {
    claims: ParsedToken;
  };
  
const App: React.FC = () => {
  return (
    <div className="App">
      <h1>Post</h1>
      <Form />
      <script src="app.js"></script>
    </div>
  );
};

export default App;
