import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

const pages = ["users", "reports", "fake numbers", "posting"];

type Props = {
  signOut: () => void;
};

const Navigation = ({ signOut }: Props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const navigateTo = (page: string) => {
    setAnchorElNav(null);
    navigate(`/${page}`);
  };

  const getNavLinkColor = (page: string) => {
    return location.pathname.includes(page)
      ? "white"
      : theme.palette.text.secondary;
  };

  return (
    <AppBar position="static" sx={{ mb: "48px" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorElNav(event.currentTarget);
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => {
                setAnchorElNav(null);
              }}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => navigateTo(getPageUrl(page))}
                >
                  <Typography
                    textAlign="center"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => {
              const pageUrl = getPageUrl(page);
              return (
                <Button
                  key={page}
                  onClick={() => navigateTo(pageUrl)}
                  sx={{
                    my: 2,
                    color: getNavLinkColor(pageUrl),
                    display: "block",
                  }}
                >
                  {page}
                </Button>
              );
            })}
          </Box>

          <Button
            type="button"
            sx={{ mt: 3, mb: 2, color: "white" }}
            onClick={signOut}
          >
            Sign Out
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;

const getPageUrl = (page: string) => page.split(" ").join("");
