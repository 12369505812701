import React, { useState, useRef, useEffect } from 'react';
import { Box, Button } from "@mui/material";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, orderBy, addDoc, updateDoc, doc, setDoc, getDocs, query, where, Timestamp } from "firebase/firestore/lite";
import { ParsedToken } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase'; 


import { db } from "../../firebase";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import "./posting.css";
import { DESTRUCTION } from 'dns';

//doc content
interface Tag {
  order: number; // Or any field that represents the order
  label: string;
}
interface Entry {
  uid: string;
  username: string;
  blocked: boolean;
  created: Timestamp;
  description: string;
  type: string;
  likesCount: string;
  hashTags: string[];
  url?: string; // Optional field for URL (or make it mandatory depending on your data)
  // Add other fields based on your Firestore document structure
}

const Form: React.FC = () => {
  const [description, setDescription] = useState<string>('');
  const [userIdChoice, setUserIdChoice] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [bottomGradientColor, setBottomGradientColor] = useState<string>('#AF52DE');
  const [topGradientColor, setTopGradientColor] = useState<string>('#7A36FB');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [video, setVideo] = useState<File | null>(null);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const [recordUrl, setRecordUrl] = useState<string>('');
  // State to hold the preview URL of the selected image
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  // State to hold the preview URL of the selected video
  const [previewUrlVideo, setPreviewUrlVideo] = useState<string | null>(null);

  const unixTimestampNow = Math.floor(new Date().getTime() / 1000); // Current time in Unix timestamp

  //text copy button
  const [copySuccess, setCopySuccess] = useState<string>('');
  // Function to handle the copy action for a specific item's content
  const handleCopy = async (content: any) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySuccess(content);
    } catch (err) {
      setCopySuccess('Failed to copy');
      console.error('Failed to copy text: ', err);
    }

    // Clear the message after a short delay
    setTimeout(() => setCopySuccess(''), 2000);
  };

  // hande un/block action
  const handleBlockButton = async (currentUid: any) => {
    //get current data
    const getDataByUid = (uid: string) => {
      return entries.find((entry) => entry.uid === uid);
    }
    const currentData = getDataByUid(currentUid);
    const newBlockedValue = currentData.blocked ? false : true;
    
    const updateBlockedField = async (id: string, newValue: string) => {
      try {
        // Reference to the document by its ID
        const docRef = doc(db, 'posts', id);
    
        // Update the 'type' field
        await updateDoc(docRef, {
          blocked: newBlockedValue,
        });
        console.log(`Document with ID ${id} updated successfully`);
        if (newBlockedValue) {
          alert('Post blocked!'); 
        } else {
          alert('Post unblocked!'); 
        }
        window.location.reload();
      } catch (error) {
        alert('Error!');
        console.error('Error updating document:', error);
      }
    };

    updateBlockedField(currentUid,'yes');

    // Clear the message after a short delay
    setTimeout(() => setCopySuccess(''), 2000);
  };



  // define hashtags for prefilling
  const hashTagPrefill: { [key: string]: string } = {
    health: '\n#health',
    grower: '\n#grower',
    recipes: '\n#recipes',
    law: '\n#law',
    cbd: '\n#cbd',
    shop: '\n#shop',
  };
  // define user ids
  const userIds: { [key: string]: string } = {
    health: '6C25735D-9612-457C-A946-72C8C172CDAE',
    grower: '2B9C1F87-6FA9-4FA3-8872-1A75E1324685',
    recipes: 'CE93E03A-7C09-4AD9-983D-32115EAC076D',
    law: 'EF691954-CBEA-42F2-A25C-988379228924',
    cbd: '35C36C43-3F6E-4B7C-9FE4-3118AA8ACA4B',
    shop: '300737D9-F7E6-4BE4-BF44-B9F9596DBDCB',
  };
  // set usernames
  const usernamesList: {[key: string]: string } = {
    health: 'wee.do_health',
    grower: 'wee.do_grower',
    recipes: 'wee.do_recipes',
    law: 'wee.do_law',
    cbd: 'wee.do_cbd',
    shop: 'wee.do_shop',
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // uncomment to prevent site reloading after submit
    //e.preventDefault();

    //create uuid
    const docId = uuidv4();
    //set uid
    const userId = userIds[userIdChoice];
    //set username
    const username = usernamesList[userIdChoice];
    //extract hash tags
    const hashTags: string[] = (
      description.match(/(?:^|\s)(?:#)([a-zA-Z\d_]+)/g) ?? []
    ).map(tag => tag.trim().replace('#', ''));

    
    //set image
    if (type === 'image' && image) {
      const imageId = uuidv4();
      const filename = imageId + "-" + String(unixTimestampNow) + ".jpg";

      setLoading(true); // Show loading state during upload and submission

      try {
        setUploading(true);
        // Create a storage reference
        const storageRef = ref(storage, `feeds/photo/${filename}`);
        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, image);
        // Track upload progress
        uploadTask.on(
          'state_changed', 
          (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        }, 
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          // Get the download URL after upload is complete
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          const record = {
            id: docId,
            type: type,
            description: description || '',
            url: downloadURL,
            userId: userId,
            username: username,
            likesCount: 0,
            created: new Date(),
            country: "de",
            hashTags: hashTags,
            lowerCaseHashTags: '',
            topGradientColor: '',
            bottomGradientColor: '',
            backgroundImageURL: '',
            blocked: false
          };  
           // Step 2: Submit the Firestore record with the download URL
           
          await setDoc(doc(collection(db, 'posts'), record.id), record); 

          alert('Post submitted successfully!');
          console.log(record);
          setImageURL(downloadURL);
          setImage(null);
        });
      } catch (error) {
        console.error('Error creating post:', error); 
        alert('Error!');
      } finally {
        setUploading(false);
      }
    }

    //set video
    if (type === 'video' && video) {
      const videoId = uuidv4();
      const filename = videoId + "-" + String(unixTimestampNow) + ".mp4";

      setLoading(true); // Show loading state during upload and submission

      try {
        setUploading(true);
        // Create a storage reference
        const storageRef = ref(storage, `feeds/video/${filename}`);
        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, video);
        // Track upload progress
        uploadTask.on(
          'state_changed', 
          (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        }, 
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          // Get the download URL after upload is complete
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          const record = {
            id: docId,
            type: type,
            description: description || '',
            url: downloadURL,
            userId: userId,
            username: username,
            likesCount: 0,
            created: new Date(),
            country: "de",
            hashTags: hashTags,
            lowerCaseHashTags: '',
            topGradientColor: '',
            bottomGradientColor: '',
            backgroundImageURL: '',
            blocked: false
          };  

          // Step 2: Submit the Firestore record with the download URL
           
          await setDoc(doc(collection(db, 'posts'), record.id), record); 

          alert('Post submitted successfully!');
          console.log(record);
          setVideoURL(downloadURL);
          setVideo(null);
        });
      } catch (error) {
        console.error('Error creating post:', error); 
        alert('Error!');
      } finally {
        setUploading(false);
      }
    }

    if (type !== 'image' && type !== 'video') {

      const record = {
        id: docId,
        type: type,
        description: description || '',
        url: type === 'link' ? linkUrl : '',
        userId: userId,
        username: username,
        likesCount: 0,
        created: new Date(),
        country: "de",
        hashTags: hashTags,
        lowerCaseHashTags: '',
        topGradientColor: type === 'text' ? topGradientColor : "#7A36FB",
        bottomGradientColor: type === 'text' ? bottomGradientColor : "#AF52DE",
        backgroundImageURL: '',
        blocked: false
      };  

      console.log(record);

  
      try {
        await setDoc(doc(collection(db, 'posts'), record.id), record);
        alert('Post submitted successfully!');
      } catch (err) {
        console.error('Error creating post: ', err);
        alert('Error: '+ err);
      }
  
    }
  };
  
   //Handle emoji selection
   const handleEmojiClick = (emojiData: EmojiClickData) => {
    const emoji = emojiData.emoji;

    // Get the current textarea element
    const textArea = descriptionRef.current;

    if (textArea) {
      const startPos = textArea.selectionStart; // Get current cursor position
      const endPos = textArea.selectionEnd; // Get end cursor position (for selections)

      // Use textAreaRef.current.value to get the actual current text from the textarea, use of description did not work
      const descr = textArea.value;

      // Insert the emoji at the cursor position
      const newText = descr.slice(0, startPos) + emoji + descr.slice(endPos);

      setDescription(newText); // Update the state with the new text

      // Update the cursor position after the emoji is inserted
      setTimeout(() => {
        textArea.setSelectionRange(startPos + emoji.length, startPos + emoji.length);
        textArea.focus(); // Make sure the text area remains focused after insertion
      }, 0);
    }
  };

  const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedChoice = e.target.value;
    setUserIdChoice(selectedChoice);
    setDescription(hashTagPrefill[selectedChoice]);
  };

  // State to hold the selected ID from the form
  const [selectedId, setSelectedId] = useState<string>('');
  // State to hold the query result
  const [entries, setEntries] = useState<Entry[]>([]);
  // State to handle loading
  const [loading, setLoading] = useState<boolean>(false);

  // Handle form input changes (user selects an ID)
  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedId(e.target.value); // Update the selected ID
  };

  // Perform the Firestore query when the ID changes
  useEffect(() => {
    const fetchEntries = async () => {
      if (userIdChoice === '') {
        setEntries([]); // If no ID is selected, clear the results
        return;
      }

      const uid = userIds[userIdChoice];

      setLoading(true);
      try {
        const q = query(
          collection(db, 'posts'), 
          where('userId', '==', uid), // Replace 'idField' with the actual field
          orderBy('created', 'desc') // Sort by timestamp in descending order
          ); // 'idField' is the field you're querying by
        const querySnapshot = await getDocs(q);
        const fetchedEntries: Entry[] = [];
        querySnapshot.forEach((doc) => {
          fetchedEntries.push({ uid: doc.id, ...doc.data() } as Entry); // Combine doc id with data
        });
        setEntries(fetchedEntries);
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, [userIdChoice]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // Get the selected file
      if (file) {
        setImage(file); // Set the selected image file
        const reader = new FileReader(); // Create a FileReader object
  
        reader.onloadend = () => {
          setPreviewUrl(reader.result as string); // Set the preview URL from FileReader result
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
  };

  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // Get the selected file
      if (file) {
        setVideo(file); // Set the selected image file
        setPreviewUrlVideo(''); // Clear the existing preview URL
        const readerVideo = new FileReader(); // Create a FileReader object
  
        readerVideo.onloadend = () => {
          setPreviewUrlVideo(readerVideo.result as string); // Set the preview URL from FileReader result
        };
        readerVideo.readAsDataURL(file); // Read the file as a data URL
      }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value); // Update the state when the textarea content changes
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>User:</label><br />
          <select
            value={userIdChoice}
            onChange={handleUserChange}
            required>
            <option value="" disabled>Select an option</option>
            <option value="health">wee.do Health</option>
            <option value="grower">wee.do Grower</option>
            <option value="recipes">wee.do Recipes</option>
            <option value="law">wee.do Law</option>
            <option value="cbd">wee.do CBD</option>
            <option value="shop">wee.do Shop</option>
          </select>
      </div>
      <div>
        <label>Type:</label><br />
          <select
            value={type}
            onChange={(e) => setType(e.target.value)} required>
            <option value="" disabled>Select an option</option>
            <option value="text">Text</option>
            <option value="image">Image</option>
            <option value="video">Video</option>
            <option value="link">Link</option>
          </select>
      </div>
      {type === 'text' && (
        <div>
          <label>Text:</label><br />
          <textarea
            ref={descriptionRef}
            value={description}
            onFocus={() => setShowEmojiPicker(true)}
            //onChange={(e) => setDescription(e.target.value)}
            onChange={handleTextChange}
          />
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} className='custom-emoji-picker'/>}
          <br />
          <input
            type="color"
            value={bottomGradientColor}
            onChange={(e) => setBottomGradientColor(e.target.value)}
          />&nbsp;<label>bottomGradientColor</label>
          <br />
          <input
            type="color"
            value={topGradientColor}
            onChange={(e) => setTopGradientColor(e.target.value)}
          />&nbsp;<label>topGradientColor</label>
        </div>
      )}
      { type === 'link' && (
        <div>
        <label>Text:</label><br />
          <textarea
            ref={descriptionRef}
            value={description}
            onFocus={() => setShowEmojiPicker(true)}
            //onChange={(e) => setDescription(e.target.value)}
            onChange={handleTextChange}
          />
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} className='custom-emoji-picker'/>}
        <br />
        <label>URL:</label><br />
          <input
            type="url"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
        </div>
      )} 
      { type === 'image' && (
        <div>
        <label>Text:</label><br />
          <textarea
            ref={descriptionRef}
            value={description}
            onFocus={() => setShowEmojiPicker(true)}
            //onChange={(e) => setDescription(e.target.value)}
            onChange={handleTextChange}
          />
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} className='custom-emoji-picker'/>}
        <br />
        <label>Image:</label><br />
          <input
            type="file"
            accept='image/jpg'
            onChange={handleImageChange}
          />
          {previewUrl && (
            <div>
              <img src={previewUrl} alt="preview" style={{ maxWidth: '300px', marginTop: '20px' }} />
            </div>
          )}
          {uploading && <div>Uploading...</div>}
          {imageURL && <div>Image uploaded! URL: <a href={imageURL}>{imageURL}</a></div>}
        </div>
      )} 

      { type === 'video' && (
        <div>
        <label>Text:</label><br />
          <textarea
            ref={descriptionRef}
            value={description}
            onFocus={() => setShowEmojiPicker(true)}
            //onChange={(e) => setDescription(e.target.value)}
            onChange={handleTextChange}
          />
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} className='custom-emoji-picker'/>}
        <br />
        <label>Video:</label><br />
          <input
            type="file"
            accept='video/mp4'
            onChange={handleVideoChange}
          />
          {previewUrlVideo && (
            <video style={{ marginTop: '20px' }} width="320" height="240" controls>
              <source src={previewUrlVideo} type='video/mp4'></source>
            </video>
          )}
          {uploading && <div>Uploading...</div>}
          {videoURL && <div>Video uploaded! URL: <a href={videoURL}>{videoURL}</a></div>}
        </div>
      )} 

      <button className="button-submit" type="submit">Submit</button>
      <div>&nbsp;</div>
      <hr></hr>
      
        <h3>Posts</h3>

        {loading && <div>Loading...</div>}

        {!loading && entries.length > 0 && (
          <div className="post-container">
            {entries.map((entry) => (
              <div key={entry.uid} className={entry.blocked ? 'post-element-blocked' : 'post-element'}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <div><strong>uid:</strong> {entry.uid}</div>
                  <button className="button-copy" onClick={() => handleBlockButton(entry.uid)}>un/block post</button>
                  {copySuccess === entry.uid && <span style={{ marginLeft: '8px', color: 'green' }}>copied {copySuccess}</span>}
                </div>
                <div><strong>blocked:</strong> {entry.blocked ? 'Yes' : 'No'}</div>
                <div><strong>created:</strong> {entry.created.toDate().toLocaleString()}</div> {/* Convert timestamp to readable format */}
                <div><strong>likes:</strong> {entry.likesCount}</div>
                <div><strong>type:</strong> {entry.type}</div>
                <div><strong>text:</strong> {entry.description}</div>
                <div><strong>url:</strong> {entry.url}</div>
                {entry.type === 'link' && entry.url !== '' && <div>URL: <a href={entry.url} target="_blank" rel="noopener noreferrer">{entry.url}</a></div>}
                {entry.type === 'image' && entry.url !== '' && <div><img src={entry.url} alt="preview" style={{ maxWidth: '300px', marginTop: '20px' }} /></div>}
                {entry.type === 'video' && entry.url !== '' && 
                  <video style={{ marginTop: '20px' }} width="320" height="240" controls>
                  <source src={entry.url} type='video/mp4'></source>
                  </video>
                }
                {entry.hashTags && (
                  <div>
                    <strong>hashtags:</strong> 
                    {entry.hashTags.map((hashTags, index) => (
                        <span key={index}> #{hashTags}</span> // Render each tag in the array
                      ))}
                  </div>
                )}
                {/* Render other fields as needed */}
              </div>
            ))}
          </div>
        )}

        {!loading && entries.length === 0 && selectedId && <div>No entries found for this ID.</div>}
      

    </form>
  );
};

export default Form;
