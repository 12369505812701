// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0i2exswnxTQaXXhDgmNYaf2T575Q4tpQ",
  authDomain: "weedo-b0efb.firebaseapp.com",
  databaseURL: "https://weedo-b0efb.firebaseio.com",
  projectId: "weedo-b0efb",
  storageBucket: "weedo-b0efb.appspot.com",
  messagingSenderId: "488601213773",
  appId: "1:488601213773:web:7b01c910449a3db737b504",
  measurementId: "G-4LSMEGFVMW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west3");
const storage = getStorage(app);

// Set App Check debug token for development
if (process.env.NODE_ENV === 'development') {
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}


// Initialize App Check
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LduShoqAAAAAD70f3-SheWF8vXBNpbrsFpi60b6'),
  isTokenAutoRefreshEnabled: true // Set to true to automatically refresh App Check token
});


export { auth, db, functions, httpsCallable, storage };
